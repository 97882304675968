
.main-community{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
