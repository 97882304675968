
// main-visual
.main-visual {
    max-width: 1030px;
    .swiper-slide {
        width: 100% !important;
    }
    &__img {
        width: 100%;
        padding-top: calc(446 / 1030 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &-tabs {
        display: flex;
        flex-direction: column;
        height: 100%;
        ::v-deep {
            .v-tabs {
                height: 100%;
            }
            .v-tabs-bar,
            .v-tab {
                height: 100%;
                min-height: 40px;
                max-height: 56px !important;
            }
            .v-tab {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &-tabs {
            ::v-deep {
                .v-tabs-bar,
                .v-tab {
                    min-height: 36px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &-tabs {
            ::v-deep {
                .v-tab {
                    font-size: 16px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1780px) {
    .main-visual {
        min-height: 446px;
    }
}
