
// main-aside-banner
.main-aside-banner {
    .swiper-slide {
        width: 100% !important;
    }
    &__img {
        width: 100%;
        padding-top: calc(140 / 290 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .main-aside-banner {
        max-width: 280px;
        margin: auto;
        &__img {
            padding-top: calc(140 / 290 * 100%);
        }
    }
}
@media (min-width: 1200px) {
}
