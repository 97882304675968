
@media (min-width: 1024px) {
    .main-visual-tabs {
        display: flex;
        flex-direction: column;
        height: 100%;
        ::v-deep {
            .v-tabs-bar,
            .v-tab {
                height: 100%;
            }
        }
        .v-tabs {
            height: 100%;
            max-height: 56px;
        }
        .v-tab {
            min-height: 38px;
            font-size: 16px;
        }
    }
}
