
.v-responsive,
.main-brand-slide,
.swiper-container {
    overflow: visible;
}
.main-brand-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ::v-deep {
        .swiper-container {
            height: 100%;
        }
    }
    .swiper-slide {
        height: auto;
    }
    .swiper-control {
        bottom: auto;
        top: -22px;
        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px !important;
            height: 20px !important;
            color: var(--v-grey-darken-4);
            background-color: transparent;
            &:hover {
                background-color: transparent;
            }
        }
        .swiper-button-prev {
            left: auto !important;
            right: 44px;
        }
        .swiper-button-next {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 32px;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                display: block;
                width: 1px;
                height: 10px;
                background-color: var(--v-grey-lighten4);
            }
        }
    }
    &__img {
        width: 100%;
        padding-top: calc(64 / 164 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-brand-slide {
        .swiper-control {
            top: -32px;
        }
    }
}
@media (min-width: 1024px) {
    .main-brand-slide {
        .swiper-control {
            top: -44px;
        }
    }
}
@media (min-width: 1200px) {
}
