
.main-visual-tabs {
    .row--sm {
        --grid-gutter-sm: 4px;
    }
}
::v-deep {
    .v-label {
        font-size: 14px !important;
    }
}

.main-signature-slide {
    .swiper-slide {
        width: 100% !important;
    }
    .swiper-control {
        bottom: 30px;
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
    }
    &__img {
        width: 100%;
        padding-top: calc(258 / 318 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: var(--card-border-radius) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
