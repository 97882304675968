
::v-deep {
    .swiper-pagination {
        position: relative;
        bottom: auto !important;
        left: auto !important;
        justify-content: left;
        margin-top: 20px;
    }
}
