
.v-btn.sns-button {
    position: fixed;
    right: 14px;
    bottom: calc(18px + 44px + 14px);
    border-radius: 18px;
    overflow: hidden;
    z-index: 10000000;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.15) 0px 8px 30px;
    width: 44px !important;
    height: 44px !important;
}
@media (min-width: 1024px) {
    .sns-button {
        display: none;
    }
}
