
.categories-mobile {
    position: absolute;
    top: var(--header-body-height);
    left: 0;
    width: 100%;
    ::v-deep {
        .v-tabs-bar {
            color: var(--v-grey-darken4) !important;
        }
    }
}
