
// row
// row main-exhibition
@media (min-width: 1500px) {
    .row--main-exhibition {
        > [class*="col"] {
            &:nth-child(1) {
                flex: 0 0 58.3333333333%;
                max-width: 58.3333333333%;
            }
            &:nth-child(2) {
                flex: 0 0 41.6666666667%;
                max-width: 41.6666666667%;
            }
        }
    }
}
@media (min-width: 1700px) {
    .row--main-exhibition {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}
// row exhibition-card
@media (min-width: 1200px) {
    .row--exhibition-card {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}
@media (min-width: 1500px) {
    .row--exhibition-card {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
// card
.exhibition-card {
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    &__inner {
        height: 100%;
        padding: 30px 20px;
    }
}
@media (min-width: 768px) {
    .exhibition-card {
        &__inner {
            padding: 30px;
        }
    }
}
@media (min-width: 1024px) {
    .exhibition-card {
        &__inner {
            padding: 20px;
        }
    }
}
@media (min-width: 1500px) {
    .exhibition-card {
        &__inner {
            padding: 40px;
        }
    }
}
@media (max-width: 1700px) {
    .exhibition-card {
        background-image: none !important;
    }
}
