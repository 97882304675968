
// main-exhibition-banner
.main-exhibition-banner {
    .swiper-slide {
        width: 100% !important;
    }
    &__img {
        width: 100%;
        padding-top: calc(500 / 655 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
