
::v-deep {
    .v-input--selection-controls .v-input__slot > .v-label,
    .v-input--selection-controls .v-radio > .v-label {
        display: inline-block;
    }
}

// top link
.top-link {
    background-color: #182954;
    &__lists {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            background-color: #182954;
        }
    }
    &__list {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &:first-child::before {
            display: none;
        }
    }
    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 4px 14px;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-link {
        &__lists {
            // padding-left: 52px;
            > .top-link__list:first-child {
                margin-left: -20px;
            }
            &::after {
                display: none;
            }
        }
        &__list-link {
            height: 52px;
            padding: 6px 20px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 1024px) {
    .top-link {
        .container {
            padding: 0;
        }
    }
}
